<template>
  <div>
    <b-card>
      <!-- FILTER START -->
      <b-row>
        <b-col md="4">
          <b-form-group rules="required">
            <label class="mr-1">{{ $t("labels.datefrom") }}</label>

            <flat-pickr
              v-model="filterSelect.dateFrom"
              class="form-control"
              :config="{
                altInput: true,
                altFormat: `d-m-Y H${filterSelect.api.value == 'v2/report/players' ? '' : ':i'}`,
                enableTime: true,
                time_24hr: true,
              }"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group rules="required">
            <label class="mr-1">{{ $t("labels.dateto") }}</label>
            <flat-pickr
              v-model="filterSelect.dateTo"
              class="form-control"
              :config="{
                altInput: true,
                altFormat: `d-m-Y H${filterSelect.api.value == 'v2/report/players' ? '' : ':i'}`,
                enableTime: true,
                time_24hr: true,
              }"
            />
            <!-- <label class="mr-1 text-danger" v-if="filterSelect.dateFrom"
              >Rango maximo de 30 dias
            </label> -->
          </b-form-group>
        </b-col>
        <b-col v-if="typeUserLoged == 'Root'" md="4">
          <b-form-group>
            <label class="mr-1 text-lg" for="customRadioInline1"
              >Clientes
            </label>
            <v-select
              v-model="filterSelect.customer"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="customerOptions"
              label="name"
              @option:selected="selectCustomer"
              :reduce="(customer) => customer._id"
              placeholder="Seleccione un cliente"
              value=""
            >
              <template #option="{ name }">
                <feather-icon
                  icon="UserIcon"
                  size="16"
                  class="align-middle mr-50"
                />
                <span> {{ name }}</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label class="mr-1" for="customRadioInline1">WL </label>
            <v-select
              v-model="filterSelect.WL"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="wlOptions"
              label="name"
              :reduce="(wl) => wl._id"
              placeholder="Select WL"
              value=""
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label class="mr-1">Productos </label>
            <v-select
              v-model="filterSelect.product"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="productsOptions"
              :clearable="false"
              label="name"
              :reduce="(product) => product._id"
              placeholder="Select product"
              value=""
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label class="mr-1 text-lg" for="customRadioInline1"
              >{{ $t("labels.currency") }}
            </label>
            <v-select
              v-model="filterSelect.currency"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :options="currencyOptions"
              :reduce="(currency) => currency.value"
              label="name"
              placeholder="Selecciona una Moneda"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label class="mr-1">Api</label>
            <v-select
              v-model="filterSelect.api"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="apisOptions"
              placeholder="Seleccione la moneda"
              value="api"
            />
          </b-form-group>
        </b-col>
        <b-col class="d-flex align-items-center justify-content-end">
          <b-col md="6">
            <b-form-group>
              <label class="mr-1 text-lg" for="customRadioInline1"
                >Jugador
              </label>
              <v-select
                v-model="filterSelect.playerName"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="playerNameOptions"
                :clearable="false"
                label="username"
                :reduce="(player) => player._id"
                @search="
                  (search, loading) => {
                    this.searchPlayer(search, loading);
                  }
                "
                placeholder="Selecciona un Jugador"
              >
                <template #no-options="{ search, searching, loading }">
                  Escribe para buscar un jugador
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-col>

        <!-- <b-col md="4">
          <b-form-group :label="$t('labels.clients')">
            <v-select
              :value="$store.id"
              v-model="filterSelect.clients"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="clientsOptions"
              label="name"
              @input="fetchDataReport"
              :reduce="(status) => status.id"
              placeholder="Selecciona un Cliente"
            />
          </b-form-group>
        </b-col> -->
      </b-row>

      <b-row class="mb-1">
        <b-col class="d-flex align-items-center justify-content-end">
          <b-button
            :disabled="loadingData"
            @click="inputSelect()"
            class="text-nowrap"
            variant="primary"
          >
            <b-spinner small v-if="loadingData"></b-spinner>
            <feather-icon v-if="!loadingData" icon="SearchIcon" class="mr-50" />
            <span v-if="!loadingData" class="text-nowrap">{{
              $t("labels.search")
            }}</span>
          </b-button>
        </b-col>
      </b-row>
      <!-- FILTER END -->
    </b-card>
    <!-- TABLET START -->
    <b-card>
      <b-overlay :show="loadingData" variant="transparent">
        <b-row class="mb-1">
          <b-col md="6"></b-col>
          <b-col md="6" class="d-flex align-items-center justify-content-end">
            <b-button
              @click="downloadFile()"
              class="text-nowrap"
              :variant="dataItems.length > 0 ? 'primary' : 'secondary'"
              :disabled="dataItems.length > 0 ? false : true"
            >
              <b-spinner small v-if="exportDataLoading"></b-spinner>
              <feather-icon
                v-if="!exportDataLoading"
                :icon="dataItems.length > 0 ? 'DownloadIcon' : 'XIcon'"
                class="mr-50"
              />
              <span v-if="!exportDataLoading" class="text-nowrap">{{
                $t("labels.export")
              }}</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- table -->
        <vue-good-table
          compactMode
          :columns="columns"
          :rows="dataItems"
          :group-options="{
            enabled: true,
          }"
          :pagination-options="{ enabled: false }"
          :sort-options="{ enabled: false }"
        >
          <div slot="emptystate" class="text-center">
            No hay datos para mostrar
          </div>
          <div slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'currency'">
              <p>{{ props.row.currency }}</p>
            </div>
            <div v-if="props.column.field == 'bet'">
              <p>{{ props.row.bet | currency({ symbol: "" }) }}</p>
              <p v-if="props.row.currency !== 'USD'">{{ totalSalesToUSD(props.row) | currency({ symbol: "" }) }} USD</p>
            </div>
            <div v-if="props.column.field == 'totalBet'">
              <p>{{ props.row.totalBet }}</p>
            </div>
            <div v-if="props.column.field == 'profit'">
              <p>{{ props.row.profit | currency({ symbol: "" }) }}</p>
              <p v-if="props.row.currency !== 'USD'">{{ totalWinsToUSD(props.row) | currency({ symbol: "" }) }} USD</p>
            </div>
            <div v-if="props.column.field == 'profitNet'">
              <p>{{ props.row.profitNet | currency({ symbol: "" }) }}</p>
              <p v-if="props.row.currency !== 'USD'">{{ totalProfitToUSD(props.row) | currency({ symbol: "" }) }} USD</p>
            </div>
          </div>
          <div slot="table-header-row" slot-scope="props">
            <div :class="props.row.class">
              {{ props.row.label }}
            </div>
          </div>
        </vue-good-table>
      </b-overlay>
    </b-card>
    <!-- TABLET END -->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BTableSimple,
  BThead,
  BTfoot,
  BTh,
  BTr,
  BTd,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import StatisticCardWithLineChart from "@core/components/statistics-cards/StatisticCardWithLineChart.vue";
import moment from "moment";
import store from "@/store";
import reportsStoreModule from "@/store/reports";
import { mapActions } from "vuex";
import { props } from "vue-prism-component";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    BTableSimple,
    BThead,
    BTh,
    BTfoot,
    BTr,
    BTd,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    BSpinner,
    ToastificationContent,
    StatisticCardHorizontal,
    StatisticCardWithAreaChart,
    StatisticCardWithLineChart,
    moment,
    axios,
  },
  data() {
    return {
      exportDataLoading: false,
      dataItems: [],
      apisOptions: [
        { label: "Api V1", value: "report/players" },
        { label: "Api V2", value: "v2/report/players" },
      ],
      currencyOptions: [
        { name: "Todas", value: "all" },
        { name: "ARS", value: "ARS" },
        { name: "BRL", value: "BRL" },
        { name: "CLP", value: "CLP" },
        { name: "COP", value: "COP" },
        { name: "EUR", value: "EUR" },
        { name: "MXN", value: "MXN" },
        { name: "MYR", value: "MYR" },
        { name: "PEN", value: "PEN" },
        { name: "PYG", value: "PYG" },
        { name: "USD", value: "USD" },
        { name: "UYU", value: "UYU" },
        { name: "VES", value: "VES" },
        { name: "XAF", value: "XAF" },
      ],
      playerNameOptions: [{ username: "Todos", _id: "all" }],
      columns: [
        { label: "Moneda", field: "currency" },
        { label: "Apuesta", field: "totalBet" },
        { label: "Monto apostado", field: "bet" },
        { label: "Ganancia", field: "profit" },
        { label: "Ganancia neta", field: "profitNet" },
      ],
      currencies:[],
      totals: {},
      timeout: null,
      customerOptions: [
        {
          _id: "all",
          name: "Todos",
        },
      ],
      minDate: null,
      maxDate: null,
      filterSelect: {
        dateTo: null,
        dateFrom: null,
        customer: "all",
        product: "all",
        currency: "all",
        playerName: "all",
        q: "",
        WL: "",
        api: { label: "Api V2", value: "v2/report/players" },
      },
      productsOptions: [
        {
          _id: "all",
          name: "Todos",
        },
      ],
      wlOptions: [],
      loadingWlOptions: false,
      timeoutSearchPlayer: null,
      loadingData: false,
      timeOutSearchCustomer: null,
    };
  },
  computed: {
    typeUserLoged() {
      return this.$store.getters["usersModule/typeUser"];
    },
    userLoged() {
      return this.$store.getters["usersModule/userLoged"];
    },
  },
  created() {
    this.filterSelect.dateFrom = moment()
      .subtract(29, "days")
      .format("YYYY-MM-DD 00:00");
    this.filterSelect.dateTo = moment().format("YYYY-MM-DD 23:59");
    this.minDate = moment().subtract(29, "days").format("YYYY-MM-DD 00:00");
    this.maxDate = moment().format("YYYY-MM-DD 23:59");
    this.getExchangeRates();
  },
  async mounted() {
    // Register module
    if (!store.hasModule("reports"))
      store.registerModule("reports", reportsStoreModule);
    this.getCustomer();
    // this.getCurrencys()
    this.getProducts("all");
    // this.inputSelect()
  },
  methods: {
    ...mapActions("reports", ["downloadReportFile"]),
    async getExchangeRates() {
      const res = await this.$store.dispatch("currenciesModules/getCurrencies");
      this.currencies = res;
    },
    totalWinsToUSD(row) {
      for (let data of this.currencies) {
        if (row.currency == data.currency) {
          row.totalWinsUSD = row.profit * data.rate;
          return row.totalWinsUSD;
        } else if (row.currency == "USD") {
          row.totalWinsUSD = row.profit;
          return row.totalWinsUSD;
        }
      }
    },
    totalSalesToUSD(row) {
      for (let data of this.currencies) {
        if (row.currency == data.currency) {
          row.totalSalesUSD = row.bet * data.rate;
          return row.totalSalesUSD;
        } else if (row.currency == "USD") {
          row.totalWinsUSD = row.bet;
          return row.totalWinsUSD;
        }

      }
    },
    totalProfitToUSD(row) {

      for (let data of this.currencies) {
        if (row.currency == data.currency) {
          row.profitUSD = row.profitNet * data.rate;
          return row.profitUSD;
        } else if (row.currency == "USD") {
          row.totalWinsUSD = row.profitNet;
          return row.totalWinsUSD;
        }

      }
    },
    downloadFile() {
      this.exportDataLoading = true;
      const VUE_APP_GATEWAY = process.env.VUE_APP_GATEWAY;
      const customer =
        this.filterSelect.customer == "all" ? "" : this.filterSelect.customer;
      const filter = {
        customerId: customer || this.userLoged.customerId,
        dateFrom: moment(this.filterSelect.dateFrom)
          .utc()
          .format("YYYY-MM-DD HH:mm"),
        dateAt: moment(this.filterSelect.dateTo)
          .utc()
          .format("YYYY-MM-DD HH:mm"),
        product: this.filterSelect.product,
        currency: this.filterSelect.currency,
        player: this.filterSelect.playerName,
        WL: this.filterSelect.WL,
      };
      const finalUrl = VUE_APP_GATEWAY + "report/players-products";
      const format = ".xlsx";
      const customerName = this.customerOptions.find(
        (customer) => customer._id == filter.customerId
      );
      const fileTitle = `profit-players-product-${filter.product}-${
        this.filterSelect.customer == "all"
          ? "ALL"
          : customerName?.name.toUpperCase()
      }-(${filter.dateFrom + " - " + filter.dateAt})${format}`;

      this.downloadReportFile({
        filter: filter,
        fileTitle: fileTitle,
        url: finalUrl,
      })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("labels.errorDownloadFile"),
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `${error.message}`,
            },
          });
        })
        .finally(() => (this.exportDataLoading = false));
    },
    async searchCustomer(search, loading) {
      loading(true);
      try {
        clearTimeout(this.timeOutSearchCustomer);

        this.timeOutSearchCustomer = setTimeout(async () => {
          const res = await this.$store.dispatch(
            "customerModule/getCustomers",
            {
              q: search,
              paginate: {
                page: 1,
                perPage: 20,
                sortDesc: true,
              },
            }
          );
          this.customerOptions = res.data.customers;

          const itemAll = {
            _id: "all",
            name: "Todos",
          };
          this.customerOptions.unshift(itemAll);

          loading(false);
        }, 500);

        loading(false);
      } catch (error) {
        console.log(error);
      }
    },
    async getWlOptions() {
      try {
        this.loadingWlOptions = true;
        const customerSelected = this.customerOptions.find(
          (customer) => customer._id == this.filterSelect.customer
        );
        const res = await this.$store.dispatch("reports/getWlOptions", {
          customerId: customerSelected.customerId,
        });
        this.wlOptions = res.data;
        this.filterSelect.WL = "";
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingWlOptions = false;
      }
    },
    async fetchDataReport() {
      try {
        this.loadingData = true;
        const customer =
          this.filterSelect.customer == "all" ? "" : this.filterSelect.customer;
        const params = {
          customerId: customer || this.userLoged.customerId,
          dateFrom: moment(this.filterSelect.dateFrom)
            .utc()
            .format("YYYY-MM-DD HH:mm"),
          dateAt: moment(this.filterSelect.dateTo)
            .utc()
            .format("YYYY-MM-DD HH:mm"),
          product: this.filterSelect.product,
          currency: this.filterSelect.currency,
          player: this.filterSelect.playerName,
          urlApi: this.filterSelect?.api?.value,
        };
        const res = await this.$store.dispatch(
          "reports/fetchReportPlayers",
          params
        );
        this.dataItems = res.data.data.map((item) => {
          return {
            mode: "span",
            label: item.product,
            html: false,
            children: item.currencies.map((currency) => {
              return {
                currency: currency.currency,
                bet: currency.bet,
                totalBet: currency.totalBet,
                profit: currency.profit,
                profitNet: currency.profitNet,
              };
            }),
          };
        });
        if (this.dataItems.length > 0) {
          let totalUsd = {
            currency: "All",
            bet: 0,
            totalBet: 0,
            profit: 0,
            profitNet: 0,
          }
          this.dataItems.push({
            mode: "span",
            html: false,
            label: "Totales",
            class: "text-center",
            children: res.data.totals.map((total) => {
              return {
                currency: total.currency,
                bet: total.bet,
                totalBet: total.totalBet,
                profit: total.profit,
                profitNet: total.profitNet,
              };
            }),
          });
          console.log("res.data.totals", res.data.totals)
          for (let items of res.data.totals) {
            for (let currency of this.currencies) {
              if (currency.currency === items.currency) {
                totalUsd.bet = totalUsd.bet + (items.bet * currency.rate);
                totalUsd.totalBet = totalUsd.totalBet + items.totalBet
                totalUsd.profit = totalUsd.profit + (items.profit * currency.rate);
                totalUsd.profitNet = totalUsd.profitNet + (items.profitNet * currency.rate);
              } else if (items.currency === "USD") {
                console.log(items, "||||||||||||||||||||")
                totalUsd.bet = totalUsd.bet + items.bet;
                totalUsd.totalBet = totalUsd.totalBet + items.totalBet
                totalUsd.profit = totalUsd.profit + items.profit
                totalUsd.profitNet = totalUsd.profitNet + items.profitNet;
                break;
              }
            }
           }
           this.dataItems.push({
            mode: "span",
            html: false,
            label: "Totales USD",
            class: "text-center",
            children: [totalUsd],
          });
        }
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching data list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    async searchPlayer(search, loading) {
      if (!search) return;

      try {
        clearTimeout(this.timeoutSearchPlayer);
        this.timeoutSearchPlayer = setTimeout(async () => {
          loading(true);
          const customerSelect = this.customerOptions
            ? this.customerOptions.find(
                (item) => item._id == this.filterSelect.customer
              )
            : null;
          const customerId =
            this.filterSelect.customer == "all"
              ? ""
              : customerSelect
              ? customerSelect.customerId
              : "";
          const payload = {
            customerId: customerId || this.userLoged.customerId,
            player: search,
          };
          const res = await this.$store.dispatch(
            "reports/fetchUsersPlayers",
            payload
          );
          this.playerNameOptions = res.data.users;
          this.playerNameOptions.unshift({ username: "Todos", _id: "all" });
        }, 500);
      } catch (error) {
      } finally {
        setTimeout(() => {
          loading(false);
        }, 900);
      }
    },
    inputSelect() {
      if (
        this.filterSelect.dateFrom !== "" &&
        this.filterSelect.dateTo !== "" &&
        this.filterSelect.playerName
      ) {
        // this.serverParams.page = 1;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.fetchDataReport();
        }, 500);
      }
    },
    inputDateFrom(date) {
      // minDate restarle 30 dias a la fecha actual
      // maxDate sumarle 30 dias a la fecha actual
      // this.minDate = moment(date).subtract(29, 'days').format('YYYY-MM-DD 00:00')
      // this.maxDate = moment(date).add(29, 'days').format('YYYY-MM-DD 23:59')
      // this.filterSelect.dateTo = moment(date).add(29, 'days').format('YYYY-MM-DD 23:59')
    },
    async getCustomer() {
      if (this.typeUserLoged !== "Root") return;
      const res = await this.$store.dispatch("customerModule/getCustomers", {
        paginate: {
          page: 1,
          perPage: 200,
          sortDesc: true,
        },
      });
      this.customerOptions = res.data.customers;
      // agregar al principio
      const itemAll = {
        _id: "all",
        name: "Todos",
      };
      this.customerOptions.unshift(itemAll);
    },
    async getCurrencys() {
      const res = await this.$store.dispatch("reports/getCurrencys");
    },
    async getProducts(fetch) {
      if (fetch == "all") {
        const res = await this.$store.dispatch("reports/getProducts");
        this.productsOptions = res.data;
        // agregar al principio
        const itemAll = {
          _id: "all",
          name: "Todos",
        };
        this.productsOptions.unshift(itemAll);

        if (this.typeUserLoged !== "Root") {
          this.filterSelect.customer = this.userLoged.customerId;
        }
      }
    },
    selectCustomer(customer) {
      if (customer._id == "all") {
        this.getProducts("all");
        return;
      }
      this.productsOptions = customer.products;
      // agregar al principio
      const itemAll = {
        _id: "all",
        name: "Todos",
      };
      this.productsOptions.unshift(itemAll);
      this.filterSelect.product = "all";
      this.filterSelect.playerName = "all";
      // this.inputSelect()
      this.getWlOptions();
    },
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
